import './App.css';
import ChromeDinoGame from 'react-chrome-dino';

function App() {
  return (
    <div className="App">
      <ChromeDinoGame />
    </div>
  );
}

export default App;
